$primary: #2869B4; // Color taken from  SDI logo
// $primary-light: #4480C4;

@import "~bulma/sass/utilities/_all";

$navbar-height: 5rem; // Needed so the body content is not hidden behind the navbar
$card-header-background-color: $primary;

@import '~bulma';

@import '~leaflet/dist/leaflet.css';

// Allow the logo img to control its height
// Otherwise, the navbar set a max-height which distorts the logo
.navbar-logo {
    max-height: unset !important;
}

// Fix mouse cursor when the input file is disabled
.upload-file-button {

    .file-input {
        cursor: pointer;
    }

    .file-input[disabled] {
        cursor: not-allowed;
    }
}

.message {
    .message-body {
        &.no-border {
            border-width: 0px;
        }
    }
}


.pinned-dcs-scrollable-list {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #dbdbdb;
    border-width: 2px 0;
    padding: 15px 0;

    .columns {
        .column:first-child {
            padding-left: 1.25rem;
        }
    }

    .columns:hover {
        background-color: #f5f5f5;
    }
}

.marker-icon {
    // The following transform is needed to anchor the .marker-icon
    // element to the center of the marker and not the top-left corner
    transform: translate(calc(-50% + 6px), calc(-50% + 6px));
    // Delegate control of the width and height to the children
    width: 100%;
    height: 100%;
    // Center the content vertically and horizontally
    display: flex;
    justify-content: center;
    align-items: center;
}

// This is a hack to quickly add an icon to the leaflet control
// The real solution should be to create a custom react control
// https://react-leaflet.js.org/docs/example-react-control/
// This would also allow to have Bulma styling for the text and checkbox
// But this require more work than can be done in the given time
.leaflet-control-layers-toggle {
    // background-image: url(http://localhost:8080/sdilogo.png);
    &::before {
        font-family: "Font Awesome 6 Free";
        content: "\f1de";
        font-weight: 900;
        position: absolute;
        top: 0px;
        left: 7px;
        font-size: 30px;
    }
}
